img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    pointer-events: none;
}
.container{
    height: 600px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.svg-main{
    width: 100vw;
    position: absolute;
    bottom: 0;
}

.tr-origin-bottom{
    transform-origin: 0% 100%;
}
body {
    overflow-x: hidden;
}

:root {
    --chakra-colors-chakra-subtle-bg: "white";
}
/* .featuredImage :hover {
    transition: all 1s ease-in-out;
    transform: translate3d(0px, -10px, 0px);
} */
